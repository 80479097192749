@import '~antd/dist/antd.css';

@font-face {
  font-family: AvenirLTStdBook;
  src: url(../fonts/AvenirLTStd-Book.otf);
}

@font-face {
  font-family: AvenirLTStdBookOblique;
  src: url(../fonts/AvenirLTStd-BookOblique.otf);
}

@font-face {
  font-family: AvenirLTStdHeavy;
  src: url(../fonts/AvenirLTStd-Heavy.otf);
}

@font-face {
  font-family: AvenirLTStdHeavyOblique;
  src: url(../fonts/AvenirLTStd-HeavyOblique.otf);
}

@font-face {
  font-family: GothamRoundedBold;
  src: url(../fonts/Gotham-Rounded-Bold.ttf);
}

#root {
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 100%;
  background-size: 100% 100%;
  min-height: 100vh;
}

p {
  font-family: AvenirLTStdBook;
  color: #000000;
  font-size: 16px;
  margin-bottom: 5px;
  word-break: break-word;
}
h3,
h5,
h6 {
  font-family: AvenirLTStdHeavy;
  margin-bottom: 8px;
}

h4 {
  font-family: AvenirLTStdHeavy;
}

h3 {
  color: #050593;
  font-size: 24px;
}
h4 {
  color: #000000;
  font-size: 20px;
}
h5 {
  color: #999999;
  font-size: 16px;
}
h6 {
  color: #000000;
  font-size: 16px;
  font-weight: normal;
}

strong {
  font-family: AvenirLTStdHeavy;
  font-weight: normal;
}

// TODO: reduce styles from here, instead of adding more

textarea.ant-input {
  height: 100px;
}

.book-oblique {
  font-family: AvenirLTStdBookOblique;
  color: #000;
  font-size: 16px;
}

.heavy-oblique {
  font-family: AvenirLTStdHeavyOblique;
  color: #050593;
}

.heavy {
  font-family: AvenirLTStdHeavy;
  color: #050593;
}

.msg-error {
  color: #cf4242 !important;
}

.msg-notice {
  color: #050593 !important;
}

.caption {
  font-family: AvenirLTStdHeavy;
  font-size: 14px;
  color: #707070;
}

.msg-response {
  color: #0a8e4f !important;
  margin-top: 10px;
}

.divider {
  position: relative;
  margin-top: 10px;
  height: 1px;
  &:after {
    content: '';
    border: 1px solid #eee;
    position: absolute;
    bottom: 0;
    left: -40px;
    right: -40px;
  }
}

.link-modal {
  text-decoration: underline;
  color: #050593;
  float: right;
  cursor: pointer;
}

.ant-btn-link {
  font-family: AvenirLTStdBook;
  text-decoration: underline;
  color: #050593;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
  align-items: center;
  transition: 0.5s;
  &:hover {
    color: #050593;
    opacity: 0.7;
  }
}

.bnt-modal-custom {
  font-size: 16px;
  font-family: AvenirLTStdBook;
  span {
    transform: translateY(2px);
  }
}

.buton-icon {
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
  }
}

.mt1 {
  margin-top: 10px;
}
.mt3 {
  margin-top: 30px;
}

.ph1 {
  padding: 10px;
}

.pb1 {
  padding-bottom: 10px;
}

.pb2 {
  padding-bottom: 20px;
}

.mb3 {
  margin-bottom: 30px;
}

.inline-right {
  float: right;
  display: inline-block;
}

.input-wrapper {
  width: 260px;
  margin-right: 20px;
  float: left;
  border: 1px;
}

.ant-calendar-picker,
.ant-input-number,
.ant-select {
  width: 100%;
  font-size: 16px;
  font-family: AvenirLTStdBook;
}

.unit-wrapper {
  float: right;
  .unit-text {
    font-size: 14px;
    cursor: pointer;
    &.unChecked {
      text-decoration: underline;
      color: #050593;
    }
  }
  .devide {
    margin: 0 5px;
  }
}

.ant-popover {
  max-width: 350px;
  h6 {
    margin-bottom: 0;
  }
  .ant-popover-title {
    border-bottom: none;
    padding: 10px 16px 0;
  }
  .ant-popover-title h4 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .ant-popover-inner-content {
    padding: 10px 16px;
    line-height: 1.2;
  }
}

.modal-form {
  top: 10%;
  .ant-modal-body {
    padding: 15px 25px 10px 25px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.modal-custom {
  .ant-modal-footer {
    border-top: none;
  }
}

.suggestion-item {
  background-color: #ffffff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
  cursor: pointer;
}

.autocomplete-dropdown-active {
  position: absolute;
  width: calc(100% - 20px);
  background-color: #fff;
  z-index: 3;
  padding: 5px 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-dropdown-menu-item,
.ant-select-selection-selected-value,
.ant-calendar-picker-input,
.ant-input {
  font-family: AvenirLTStdBook;
  color: #000000;
}

//<-- Start of Hacks for Input Alignment --

.ant-calendar-picker-input.ant-input {
  line-height: 3;
  font-size: 16px;
}

.ant-calender-input {
  font-size: 16px;
}

.ant-calendar-input:placeholder-shown {
  font-family: AvenirLTStdBook;
  font-size: 16px;
}

.ant-input {
  font-size: 16px;
  padding-top: 8px;
}

// -- End of Hacks for Input Alignment -->

.location-search-input {
  input {
    font-family: AvenirLTStdBook;
    color: #000000;
    font-size: 16px;
    // Hack for Input Alignment
    padding-top: 8px;
  }
  span.ant-input-suffix {
    color: rgba(0, 0, 0, 0.25);
    transform: scale(0.8) translateY(-7px);
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.ant-checkbox-wrapper {
  font-family: AvenirLTStdBook;
  font-size: 16px;
  color: #000;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.button-collapse {
  font-family: AvenirLTStdBook;
  color: #050593;
  font-size: 16px;
  border: 1px solid #050593;
  border-radius: 1px;
  background-color: #ffffff;
  padding: 6px 15px;
  cursor: pointer;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #050593;
  border-color: #050593;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #050593;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  animation: none;
}

.ant-select-selection__placeholder {
  margin-top: -9px;
}

.ant-message-custom-content span {
  color: #000;
}

// TODO: Temporary styles for Modal.confirm , should find a way to add it to the component

.ant-modal-confirm {
  .anticon {
    display: none;
  }
  .ant-modal-confirm-content {
    font-family: AvenirLTStdHeavy;
    font-size: 20px;
    line-height: 1.2;
    color: #cf4242;
  }
  .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-confirm-btns {
    .ant-btn {
      border: 1px solid #050593;
      color: #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
    }
    button {
      padding: 0 30px;
    }
    button + button {
      margin-left: 15px;
    }
    .ant-btn-primary {
      background: #050593 0% 0% no-repeat padding-box;
      border-radius: 4px;
      border: 1px solid #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #ffffff;
      &:hover,
      &:focus,
      &:active {
        background-color: #050593;
        color: #fff;
        border: 1px solid #050593;
      }
      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background-color: #9b9bd4;
        color: #fff;
        border: 1px solid #9b9bd4;
      }
    }
  }
}

.b2b-confirm-modal {
  .ant-modal-confirm-content {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    font-weight: normal;
    margin-left: 0px!important;
    color: #000000;
  }
  .ant-modal-confirm-title {
    font-family: AvenirLTStdHeavy;
    font-size: 20px;
    color: #050593;
  }
}

// TODO: reduce styles from here, instead of adding more
.AutoCompleteDropdown {
  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    font-family: AvenirLTStdHeavy;
    font-weight: normal;
  }
  .ant-select-dropdown-menu-item {
    font-family: AvenirLTStdBook;
    color: #000;
    font-size: 16px;
    white-space: pre-wrap;
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f2f2f9;
  }
  .ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected
    .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected:hover
    .ant-select-selected-icon {
    color: #050593;
  }
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header{
  margin-bottom: -36px;
}